import { postData, deleteData } from './index';

import {
  ERROR_FILE,
  DELETE_FILE,
  POST_FILE,
} from './types';

export async function postFileAction(dispatch, formData) {
  const url = '/file';
  let file;

  await postData(POST_FILE, ERROR_FILE, url, dispatch, formData, true).then((response) => {
    file = response;
  });

  dispatch({
    type: POST_FILE,
    payload: file.files[0],
  });
}

export async function deleteFileAction(dispatch, id) {
  const url = `/file/${id}`;
  let file;

  await deleteData(ERROR_FILE, url, dispatch).then((response) => {
    file = response;
  });

  dispatch({
    type: DELETE_FILE,
    payload: {
      file: null,
      message: `${file.message} ${id}`,
    },
  });
}
